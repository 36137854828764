@import "./variables";
@import "./mixins";
@import "./helpers";
@import "bootstrap/scss/bootstrap";
@import "react-alice-carousel/lib/scss/alice-carousel.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import "../components/common/Header/Header";
@import "../components/common/Footer/Footer";

section{
    padding: 50px 0;
}

#title{
    margin-top: 100px;
    text-align: center;
    color: $color-black;
    background-color:$color-sec;
    
    h1{
        margin: 25px;
        font-size: 50px;
    }
}

body{
    font-family: 'Quicksand', sans-serif;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif !important;
}

.card{
    padding: 50px;
    box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.25);

    &.blue{
        background: $color-prim;
        color: $color-white;
    }

    &.yellow{
        background: $color-sec;
        color: $color-black;
    }

}

p{
    text-align: justify;
}