.footer{
    background: $color-prim;
    color: $color-white;

    h5{
        color: $color-sec;
    }
    
    a{
        text-decoration: none;
        color: $color-white;
    }
}