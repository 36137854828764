@import "../../../styles/styles";

.contact-page{

    .contact-box{
        border: black 1px solid;
        box-shadow: 0px 10px 5px 0px rgb(0 0 0 / 25%);
        
        h1{
            margin-bottom: 50px;
            text-align: center;
        }

        .contact-box-1{
            background-color: $color-prim;
            color: $color-white;
            padding: 30px;

            input, textarea{
                border: none;
                background: transparent;
                color: $color-white;
                border-bottom: $color-white 1px solid;
                margin-bottom: 50px;
            }

            button{
                background: $color-sec;
                color: $color-black;
                padding: 10px 20px;
            }
        }
    
        .contact-box-2{
            background-color: $color-sec;
            color: $color-black;
            padding: 30px;
        }
    }

    .contact-text{
        text-align: left;
        padding-left: 20px;

        span{
            display: block;
            padding-top: 30px;
            font-size: 18px;

            svg{
                margin-right: 20px;
            }

            a{
                color: $color-black;
            }
        }
    }
}