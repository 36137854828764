@import "../../../styles/styles";

.donate-page{
    .card{
        
        h3{
            margin-bottom: 20px;
        }

        button{
            background: $color-sec;
            color: $color-black;
            width: 200px;
        }
    }
}

a:hover{
    text-decoration: none !important;
}