@import "../../../styles/styles";

#actions-section{
    .blog-col{
        margin: 20px;
        padding: 0;

        &.yellow-bg{
            background: $color-sec;

            .blog-date{
                border-right: 3px solid $color-prim;
            }
        }

        &.blue-bg{
            background: $color-prim;
            color: white;

            .blog-date{
                border-right: 3px solid $color-sec;
            }
        }

        img{
            width: 100%;
        }

        .blog-date {
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 50px;

            h2{
                padding-left: 10px;
            }
        }

        .blog-heading{
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &:hover{
            cursor: pointer;
            box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.25);
        }
    }

    a{
        color: black;

        &:hover{
            text-decoration: none;
        }
    }
}

.activity-page{
    .blog-heading h2{
        padding-left: 20px;
    }
}


.activity-page, .drive-page{
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: 50vh;
    }
}


.data-desc p{
    font-size: 20px;
}