@import "../../../styles/styles";

.team-page{

    h2{
        margin-bottom: 50px ;
    }

    .grid-col{

        margin-bottom: 20px;

        .border-div{
            margin: 1px 0;
            border: solid $color-sec 2px;
            height: 100%;

            .grid-img{
                background: $color-prim;

                img{
                    padding: 30px 10px;
                    width: 200px;
                    height: 300px;
                }
            }

            h3{
                color: $color-prim;
            }
    
            h5{
                font-style: italic;
                color: $color-prim;
            }

            // p{
            //     text-align: justify;
            //     text-justify: inter-word;
            // }
        }
    }
}

@media screen and (max-width: 767px){
    .team-page{
    
        .grid-col{
        
            .border-div{
    
                .grid-img{
    
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
    
            }
        }
    }
}