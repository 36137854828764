.bg-dark{
    background: $color-prim !important;
}

.navbar{

    height: 100px;
    width: 100%;

    .nav-link{

        margin-right: 50px;
        color: $color-sec !important;
        font-size: 1.2rem;
        font-family: 'Montserrat', sans-serif !important;

        &.active{
            color: $color-white !important;
        }
    }

    .navbar-collapse{
        z-index: 999;
        background: $color-prim !important;

        a{
            padding-left: 20px;
        }

    }

    // .dropdown-menu{
    //     background: $color-sec !important;

    //     .dropdown-item{
    //         color: $color-prim !important;
    //     }

    // }
}

@media (max-width: 1024px){
    .navbar .nav-link {
        margin-right: 10px !important;
    }
}

