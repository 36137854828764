@import "../../../styles/styles";

.home-page{
    section{
        padding: 50px;
    }

    .card{
        padding: 0;
    }

    #section1{
        margin-top: 100px;
        .carousel-item{
            max-height: 80vh;

            .carousel-caption {
                h3{
                    background: rgba(red($color-prim),green($color-prim),blue($color-prim),0.5);
                    font-size: 80px;
                }
            }
        }
    }

    @media screen and (max-width: 768px){
        #section1{
            .carousel-item{
                max-height: 200px;
    
                .carousel-caption {
                    h3{
                        font-size: 40px;
                    }
                }
            }
        }
    }
    

    #section2{
        a{
            color: $color-black;
            text-decoration: none;
    
            .card{
                min-height: 100%;
                padding: 0;
            }
        }

        .card-img-overlay {
            background: rgba(0,0,0,0.2);
    
            .card-title{
                font-size: 1.8rem;
                color: $color-sec;
                -webkit-text-stroke: .2px $color-prim;
                width: 100%;
            }
    
            &:hover{
                background: rgba(red($color-sec),green($color-sec),blue($color-sec),0.5);
    
                .card-title{
                    color: $color-prim;
                }
            }
        }
    }

    #section3{
        background: $color-prim;
        color: $color-white;
    }

    #section4{
        background-color: $color-sec;
    }

    #section5{
        background: $color-prim;
        color: $color-black;

        .testimonial-card{
            margin-top: 30px;

            img{
                height: 600px;
            }

            .card-img-overlay {
                background: rgba(red($color-sec), green($color-sec), blue($color-sec), 0.6);
            }

            button {
                padding: 10px;
                background: $color-prim;
                position: absolute;
                left: 50%;
                margin-left: -100px;
                bottom: 50px;
                width: 200px;
            }

            @media (min-width: 561px){

                img{
                    height: 350px;
                }

            }

            
        }
    }

    #section6{
        .ticker {
            overflow: hidden;

            .ticker-move{
                display: flex;
            }

            a {
                flex-shrink: 0;
                width: 20%;
                box-sizing: border-box;
                padding: 10px;
                text-align: center;
            }

            @media (max-width: 767px){
                a{
                    width: 50%;
                }
            }
        }
        
        @keyframes tickerh {
            0% { transform: translate3d(100%, 0, 0); }
            100% { transform: translate3d(-355%, 0, 0); }
        }

        .ticker-move { animation: tickerh linear 15s infinite; }
        .ticker-move:hover { animation-play-state: paused; }

        @media (max-width: 767px){
            @keyframes tickerh {
                0% { transform: translate3d(100%, 0, 0); }
                100% { transform: translate3d(-800%, 0, 0); }
            }
        }

        .ticker-move { animation: tickerh linear 50s infinite; }
        .ticker-move:hover { animation-play-state: paused; }

        
    }
    
}