@import "../../../styles/styles";

.objective-page{
    
    .card{
        h3{
            margin-bottom: 20px;
        }
    }
}

.eoto-page{
    #section1{
        img{
            max-width: 100%;
        }

        .card{
            padding: 20px;
            background: $color-prim;
            color: $color-white;
        }
    }
}

@media screen and (max-width: 767px){
    .card{

        .text{
            padding-top: 20px;

            &.text-right{
                text-align: left !important;
            }
        }
    }
}