@import "../../../styles/styles";

.alice-carousel{

    .alice-carousel__next-btn {
        text-align: left;
    }

    .alice-carousel__stage-item{
        text-align: center;
    }
}

.online-activities-page{
    .card{
        display: flex;
        flex-flow: column;
        height: 100%;

        h3{
            flex: 0 1 auto;
        }

        p{
            flex: 1 1 auto;
        }

        .media-carousel{
            flex: 0 1 100px;
        }
    }
}